<template>
    <v-container class="col-12 col-lg-8 col-xl-8 mx-auto pt-8">
        <v-card class=" glass--bg">
            <active-orders/>
        </v-card>
    </v-container>
</template>

<script>
    import ActiveOrders from "../components/orders/activeOrders";

    export default {
        name: "OwnOrders",
        components: {ActiveOrders},
        data() {
            return {}
        },
        computed: {}

    }
</script>

<style scoped>

</style>